import React, { useState } from "react"

import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import linkResolver from "../utils/linkResolver"

import axios from "axios"
import { useForm } from "react-hook-form"
import { regEmail } from "../utils/utils"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import '../styles/partnership/content.scss'
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

const PartnershipsPage = ({ data, pageContext: { locale }, location }) => {
  const [partnerFormSent, setPartnerFormSent] = useState("")
  const PartnershipsPageData = data.prismic.partnershipspage
  const [selectedTab, setSelectedTab] = useState(PartnershipsPageData.body[0])
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async formData => {
    setPartnerFormSent({ sending: true })
    const captchaToken = await executeRecaptcha("partners")
    axios
      .post("/.netlify/functions/contactForm", { formData, captchaToken })
      .then(function(response) {
        setPartnerFormSent(response)
      })
      .catch(function(error) {
        setPartnerFormSent({ error: error.message })
      })
  }

  return (
    <>
      <SEO
        title={PartnershipsPageData.meta_title}
        desc={PartnershipsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: PartnershipsPageData.title,
          subtitle: PartnershipsPageData.subtitle,
          image: {
            image: PartnershipsPageData.main_image,
            sharp: PartnershipsPageData.main_imageSharp,
          },
        }}
      />

      <section className="section is-small" css={partnershipsCss}>
        <div className="container">
          <div className="tabs is-centered">
            <ul>
              {PartnershipsPageData.body.map(category => (
                <div key={category.primary.category_id} className="">
                  <li
                    className={`tab__bubble mx-2 my-2 ${
                      category.primary.category_id ===
                      selectedTab.primary.category_id
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <a
                      className="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-weight-medium py-2 px-5"
                      onClick={() => setSelectedTab(category)}
                    >
                      {category.primary.category_name &&
                        RichText.asText(category.primary.category_name)}
                    </a>
                  </li>
                </div>
              ))}
            </ul>
          </div>

          <div className="tabs-content is-theme2 content__container">
            {selectedTab.primary.category_description && (
              <div className="columns is-centered">
                <div className="column is-7 has-text-centered has-text-weight-light">
                  {RichText.render(
                    selectedTab.primary.category_description,
                    linkResolver
                  )}
                </div>
              </div>
            )}

            <div className="is-centered is-multiline pt-5 tab__item">
              {selectedTab.fields.map(field => {
                return (
                  <div key={field.company_id} className="logo mx-4 my-4 partnership_logo-container" style={{minWidth: "100px", maxWidth: "164px", height: "104px", minWidth: "150px"}}>
                    <a
                      href={field.company_link ? field.company_link.url : "#"}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {field.company_image && (
                        <figure className="image box">
                          <Image
                            sharp={field.company_imageSharp}
                            image={field.company_image}
                          />
                        </figure>
                      )}
                    </a>
                    {field.company_link && field.company_title && (
                      <a
                        href={field.company_link.url}
                        target={field.company_link.target}
                      ></a>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section is-medium is-theme2" css={becomePartnerCss}>
        <div className="container">
          <div className="columns is-vcentered pb-6">
            <div className="column is-7">
              <h3 className="title is-size-3 has-text-weight-semibold">
                {PartnershipsPageData.join_title &&
                  RichText.asText(PartnershipsPageData.join_title)}
              </h3>
              <div className="is-size-5 has-text-weight-light">
                {PartnershipsPageData.join_richtext &&
                  RichText.render(
                    PartnershipsPageData.join_richtext,
                    linkResolver
                  )}
              </div>
            </div>
            <div className="column is-5 is-hidden-mobile">
              {PartnershipsPageData.join_image && (
                <figure className="image is-margin-0-auto">
                  <Image
                    sharp={PartnershipsPageData.join_imageSharp}
                    image={PartnershipsPageData.join_image}
                  />
                </figure>
              )}
            </div>
          </div>

          <div className="columns is-multiline">
            <form onSubmit={handleSubmit(onSubmit)} className="form__outline">
              {PartnershipsPageData.body1.map((field, i) => {
                const isLast = i === PartnershipsPageData.body1.length - 1
                return (
                  <div
                    key={field.primary.field_id}
                    className={"field column " + (isLast ? "is-12" : "is-half")}
                  >
                    {/* <label className="label">{field.primary.field_name}</label>*/}
                    <div className="control">
                      {field.primary.field_type === "textarea" ? (
                        <textarea
                          key={field.primary.field_id}
                          name={field.primary.field_id}
                          id={field.primary.field_id}
                          rows="1"
                          className="textarea"
                          placeholder={field.primary.field_name}
                          ref={register({
                            required: field.primary.field_is_required
                              ? true
                              : false,
                          })}
                        ></textarea>
                      ) : field.primary.field_type === "list" ? (
                        <div key={field.primary.field_id} className="select">
                          <label className="wrap">
                            <select
                              name={field.primary.field_id}
                              ref={register({
                                required: field.primary.field_is_required
                                  ? true
                                  : false,
                              })}
                            >
                              {field.fields &&
                                field.fields.map(elementList => (
                                  <option key={elementList.field_list_id}>
                                    {elementList.field_list_element}
                                  </option>
                                ))}
                            </select>
                          </label>
                        </div>
                      ) : (
                        <input
                          className={`input ${
                            errors[field.primary.field_id] ? "is-danger" : ""
                          }`}
                          type={field.primary.field_type}
                          name={field.primary.field_id}
                          placeholder={field.primary.field_name}
                          ref={
                            field.primary.field_type === "email"
                              ? register({
                                  pattern: regEmail,
                                  required: field.primary.field_is_required
                                    ? true
                                    : false,
                                })
                              : register({
                                  required: field.primary.field_is_required
                                    ? true
                                    : false,
                                })
                          }
                        />
                      )}
                      {errors[field.primary.field_id] && (
                        <p className="help is-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                )
              })}
              <input
                className="input"
                type="hidden"
                name="isPartnership"
                value="true"
                ref={register}
              />
              <div className="field mt-5">
                <div className="control">
                  {partnerFormSent === "" && (
                    <button className="button is-rounded button__primary has-text-white">
                      {PartnershipsPageData.form_button}
                      <span className="icon-arrow_right pl-2"></span>
                    </button>
                  )}
                  {partnerFormSent && partnerFormSent.sending && (
                    <button className="button is-rounded" disabled>
                      ...
                    </button>
                  )}
                  {partnerFormSent && partnerFormSent.status === 200 && (
                    <button className="button" disabled>
                      {PartnershipsPageData.form_success_message}
                    </button>
                  )}
                  {partnerFormSent && partnerFormSent.error && (
                    <div className="notification is-danger">
                      {partnerFormSent.error}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ArrowScrollToTop />
    </>
  )
}

export default PartnershipsPage

PartnershipsPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const PartnershipsPageQuery = graphql`
  query PartnershipsPageQuery($uid: String!, $locale: String!) {
    prismic {
      partnershipspage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
        main_image
        body {
          ... on PRISMIC_PartnershipspageBodyPartnerships {
            primary {
              category_id
              category_name
              category_description
            }
            fields {
              company_id
              company_title
              company_link {
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
              }
              company_image
            }
          }
        }

        join_title
        join_richtext
        join_image
        form_button
        form_success_message
        body1 {
          ... on PRISMIC_PartnershipspageBody1Form {
            type
            label
            primary {
              field_id
              field_name
              field_type
              field_is_required
            }
            fields {
              field_list_id
              field_list_element
            }
          }
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`

const partnershipsCss = css`


  .tab__bubble {
    position: relative;
    background: #fff;
    border-radius: 13px;
    border: 1px solid #dfeaff;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: transparent;
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }
  }

  .tabs {
    margin-bottom: 2.5rem;
    a,
    ul {
      border-bottom-width: 0px;
    }
    ul {
      flex-shrink: 1;
      flex-wrap: wrap;
    }
    li {
      &:hover,
      &.is-active {
        background-image: ${colors.orangeGradient};
        a {
          color: #fff;
        }
        &.tab__bubble {
          border-color: transparent;

          &:after {
            border-top-color: transparent;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .tabs {
      li {
        &.is-active {
          &.tab__bubble {
            &:after {
              border-top-color: #ee6248;
            }
          }
        }
      }
    }
    .tab__item {
      .logo {
        min-width: 150px !important;
      }
    }
  }

`

const becomePartnerCss = css`
  .image {
    max-width: 325px;
  }
  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    form {
      flex-direction: row;
    }
  }
`
